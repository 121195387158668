import cn from "classnames";
import { Field, Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { useLead, useSetLead } from "../../features/leads-funnel/application/lead-use-cases";
import { Lead } from "../../features/leads-funnel/domain/lead";
import PageStepForm from "../../organisms/page-step-form/PageStepForm";
import { PetSex } from "../../settings/pet";
import { Events, track } from "../../utils/analytics";
import { rollbar } from "../../utils/rollbar";
import * as styles from "./Sex.module.scss";

interface PetSexFormValues {
  petSex: PetSex | null;
}

interface Image {
  default: string;
}

const petSexSVG: Image = require("../../images/pages/leads/sex.svg") as Image;

const petSexSVGSrc: string = petSexSVG.default;

const Sex = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const lead: Lead = useLead();
  const setLead = useSetLead();
  const nextUrl = `/${i18n.language}/onboarding/birthdate/`;

  const getPetSexTitle = (): string => {
    const petSpecies = lead.petSpecies ? lead.petSpecies : "dog";

    return t(`onboarding.sex.title.${petSpecies}`);
  };

  const PetSexFormData = {
    initialValues: {
      petSex: null,
    },
    handleSubmit: (
      values: PetSexFormValues,
      { setSubmitting }: FormikHelpers<PetSexFormValues>
    ): void => {
      if (!values.petSex) {
        return;
      }

      setSubmitting(true);
      setLead(values)
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            setSubmitting(false);

            return;
          }

          track(Events.FORM_ANSWERED, {
            question: "Pet sex",
            answer: values.petSex,
          });

          setTimeout(() => navigate(nextUrl), 500);
        })
        .catch(() => {
          rollbar.warn("There was an error setting the lead.");
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<PetSexFormValues>) => {
      const { isSubmitting, handleSubmit } = props;

      return (
        <Form className={cn(styles.petSexForm)} onChange={() => handleSubmit()}>
          <Field type="radio" name="petSex" value={PetSex.male} id="male" disabled={isSubmitting} />
          <label htmlFor="male" className={cn(styles.male)}>
            {t("onboarding.sex.field.male")}
          </label>
          <Field
            type="radio"
            name="petSex"
            value={PetSex.female}
            id="female"
            disabled={isSubmitting}
          />
          <label htmlFor="female" className={cn(styles.female)}>
            {t("onboarding.sex.field.female")}
          </label>
        </Form>
      );
    },
  };

  return <PageStepForm title={getPetSexTitle()} image={petSexSVGSrc} formData={PetSexFormData} />;
};
export default Sex;
